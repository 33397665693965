import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes =[
  

  {
    path: '/404',
    component: () => import('@/views/error-page/404'),
    name: 'Page404',
    meta: { title: '404', noCache: true },
    hidden: true
  },
  {
    path: '/401',
    component: () => import('@/views/error-page/401'),
    hidden: true
  },
    {
        path: '/',
        name:'首页',
        component: () => import('@/views/home'),
        meta: { breadcrumb: '首页' }, // 定义面包屑文本
    },
    {
      path: '/productlist',//平台产品详情
      name:'productlist',
      component: () => import('@/views/product'),
      meta: { breadcrumb: '平台产品' }, // 定义面包屑文本
  },
    {
        path: '/case/:caseId',//平台产品详情
        name:'case',
        component: () => import('@/views/case'),
        meta: { breadcrumb: '平台产品' }, // 定义面包屑文本
    },
    {
      path: '/about',
      component: () => import('@/views/aboutUs/components/AboutUsPdf'),
      name: 'aboutUsPdf',
      meta: {
        title: '关于我们',
        breadcrumb: '关于我们'
      }
    }
  ]
const router = new VueRouter({
    mode: 'hash',
    routes,
  },
  
  );
  router.afterEach((to, from) => {
    if (to.hash) {
      setTimeout(() => {
        const element = document.querySelector(to.hash);
        if (element) {
          const top = element.offsetTop;
          window.scrollTo({ top, behavior: 'smooth' });
        }
      }, 500); // 延迟500毫秒执行滚动操作
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  });
  
  export default router;

