<template>
  <div class="header">
   
      <b-navbar toggleable="md" type="light" variant="light" fixed="top" style="
        width: 100%;
        background-color: #fdfdfd;
        border-bottom: 1px solid #ddd !important;
      ">
        <div class="container">
        <b-navbar-brand>
          <router-link to="/">
            <img src="@/assets/logo.png" alt="蓝雪智能logo" width="110" />
          </router-link>
        </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item
              v-for="section in sections"
              :key="section.id"
              v-show="section.id != 'vital2'"
              :to="'/#' + section.id"
            >
              {{ section.name }}
            </b-nav-item>
        <b-nav-item :to="'/about'"> 关于我们 </b-nav-item>
      </b-navbar-nav>

     
    </b-collapse>
  </div>
  </b-navbar>
</div>

</template>



<style lang="scss" scoped>
@import "../../style/base.scss";
a{
    color: $text-theme !important
}
.header {
  background: $white-bg;
  height: 56px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
  .navbar-light .navbar-nav .nav-link {
    color: $text-normal;
  }
}
.nav-bg {
  background: $white-bg-2;
}
.dropdown-menu{
  padding: 0px !important;
  min-width: 4rem !important;
  left: 0 !important;
}
.nav-item {
  font-weight: 500 !important;
  a {
    color: $text-theme;
    font-weight: 500;
    border-bottom: 3px solid #fff;
  }
}

.navbar-nav .active {
  color: $text-theme;
  font-weight: 600;
  border-bottom: 3px solid $text-theme;
}
.nav-item {
  position: relative;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0;
}

.nav-link {
  border: none; /* 隐藏边框 */
}
.nav-item a{
  border:0px !important
}


</style>
<script>
export default {
  name: "Header",
  data() {
    return {
      showNav: false,
      activeSection: null,
      sections: [
        {
          id: "vital1",
          name: "核心优势",
        },
        {
          id: "vital2",
          name: "关于我们",
        },
      
        {
          id: "vital4",
          name: "新闻中心",
        },
        {
          id: "vital5",
          name: "平台产品",
        },
        {
          id: "vital6",
          name: "招贤纳士",
        },
        {
          id: "vital7",
          name: "合作伙伴",
        },
      ],
    };
  },
  mounted() {
    // axios
    //     .get("https://www.themealdb.com/api/json/v1/1/categories.php")
    //     .then(response => {
    //     this.list = response.data.categories
    //   })
    //     .catch(err => {
    //     console.log(err)
    //   })
    //window.addEventListener('scroll', this.setActiveSection);
    document.querySelectorAll('.navbar-nav .nav-link').forEach((element) => {
      element.addEventListener('click', () => {
        console.log(222222)
        if (window.innerWidth < 768) {
          this.$root.$emit('bv::toggle::collapse');
        }
      });
    });
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.setActiveSection);
  },
  methods: {
    closeNavOnMobile() {
      console.log("fff")
      this.showNav = false;
    },
    setActiveSection() {
      const scrollPosition = window.pageYOffset;
      const windowHeight = window.innerHeight;

      this.sections.forEach((section) => {
        const element = document.getElementById(section.id);

        if (element.offsetTop <= scrollPosition + windowHeight * 0.5) {
          this.activeSection = section.id;
        }
      });
    },
    scrollToSection(sectionId) {
      const element = document.getElementById(sectionId);

      if (element) {
        const offset = element.offsetTop - 60; // 考虑到顶部导航栏的高度

        window.scrollTo({
          top: offset,
          behavior: "smooth",
        });
      }
    },
    isActive(sectionId) {
      return this.activeSection === sectionId;
    },
    
  },
};
</script>