<template>
  <div class="side">
    <div>
        <div class="show sideewm">
            <img src="@/assets/wx.png" class="img-fluid" width="32" alt="联系蓝雪官方客服">
            <div class="ewBox son">
                <img src="@/assets/ewm.png" width="78">
                
            </div>
        </div>
        <div class="show sideetel" style="border-top:1px solid rgba(255,255,255,.3);">
            <img src="@/assets/tel.png" class="img-fluid" width="32" alt="联系蓝雪">
            <div class="telBox son">
                <div>13124105127</div>
            </div>
        </div>
        <div class="sidetop" @click="scrollToTop" style="border-top:1px solid rgba(255,255,255,.3);">
            返回顶部
        </div>
    </div>
  </div>
</template>

<script>
export default {
 data(){
    return{
        showToTopButton: false
    };
 },
 mounted(){
    window.addEventListener("scroll", this.handleScroll);
 },
 beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.showToTopButton = window.pageYOffset > 0;
    },

    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/style/base.scss";
.side {
    position: fixed;
    width: 78px;
    right: 0;
    top: 50%;
    margin-top: -200px;
    z-index: 100;
    border: 1px solid $text-theme;
    background: $text-theme;
    border-bottom: 0;
    .son{ display: none;}
    .show{
        list-style: none;
       height: 78px;
       color: #fff;
       line-height: 78px;
        text-align: center;
        position: relative;
        cursor: pointer;
        transition: all .3s;
    }
    .sidetop{ height: 78px;line-height: 78px; cursor: pointer; color:$white-bg-2;}
    .show:hover .son{
        display:block!important;animation:fadein 1s
    }
    .telBox{
        text-align: left;
        line-height:60px;
        font-size: 16px;
        font-weight: 600;
        position: absolute;
        padding: 0 16px;
        left: -128px;
        top: 0px;
        height: 60px;
        background: $text-theme;
    }
    .ewBox{
        line-height:60px;
        height: 60px;
        position: absolute;
        left: -78px;
        top: 0px;
    }
}
@keyframes fadein{from{opacity:0}
to{opacity:1}
}
</style>