<template>
  <div id="app">
    <SideBar></SideBar>
      <Header></Header>
    <router-view :key="$route.fullPath"></router-view>
    <Footerc></Footerc>
  </div>
</template>

<script>
  import Header from "@/views/components/Header.vue";
  import Footerc from "@/views/components/Footerc.vue";
  import SideBar from "@/views/components/sideBar.vue";
export default {
  name: 'App',
  components: {
    Header,
      Footerc,
      SideBar,
  },
  mounted() {
    
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  
}
</style>
