import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import VueAnimateNumber from 'vue-animate-number';
import router from './router'
//import VueScrollReveal from 'vue-scroll-reveal';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/style/base.scss'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
Vue.use(VueAwesomeSwiper)

//Vue.use(VueAwesomeSwiper)
Vue.use(BootstrapVue)

// Vue.use(VueScrollReveal, {
//   class: 'v-scroll-reveal',
//   duration: 800,
//   scale: 1,
//   distance: '10px',
//   mobile: false
// });

Vue.use(VueAnimateNumber)

Vue.config.productionTip = true


new Vue({
  el: '#app',
  router,
  render: h => h(App)
})


// new Vue({
//   render: h => h(App),
// }).$mount('#app')